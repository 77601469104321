.block {
    position: relative;
    padding: 100px 25px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px #D4D4D4;
    border-radius: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    text-decoration: none;
    color: #292929;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    &--margin {
        margin-top: 100px;
        @media screen and (max-width: 991px) {
            margin-top: 50px;
        }
    }
    &--no-flip {
        cursor: auto;
    }
    .small-title {
        font-family: Speedee-Bd;
        font-weight: 700;
        font-size: 18px;
        position: absolute;
        top: 25px;
        max-width: calc(90% - 50px);
    }
    &.large-padding {
        padding: 200px 25px;
    }
    img, 
    video {
        width: 100%;
        max-width: 300px;
        margin: 0 auto 75px;
        display: block;
        &.arrow-link {
            margin: 0;
            width: 50px;
            position: absolute;
            bottom: 25px;
            right: 25px;
            transition: transform 600ms ease-in-out;
        }
        &.larger {
            max-width: 100%;
        }
    }
    h3 {
        padding-right: 10%;
        &.large {
            font-size: 42px;
            line-height: 54px;
        }
        &.large-ish {
            font-size: 60px;
            line-height: 74px;
        }
        &.larger {
            font-size: 90px;
            line-height: 104px;
        }
        &.no-padding {
            //padding-right: 0;
        }
        @media screen and (max-width: 991px) {
            padding-right: 0;
            &.large {
                font-size: 36px;
                line-height: 50px;
            }
        }
    }
    &:hover {
        text-decoration: none;
        color: #292929;
        box-shadow: 0px 10px 25px #d4d4d4;
        img {
            &.arrow-link {
                transform: rotate(360deg);
            }
        }
    }
    &--flip {
        position: absolute;
        top: 0;
        left: 12px;
        width: calc(100% - 23px);
        box-shadow: none;
        p {
            margin-bottom: 25px;
            padding-right: 20%;
        }
        @media screen and (max-width: 767px) {
            p {
                padding-right: 0;
            }
        }
    }
}

.block-container {
    .col-12 {
        @media screen and (max-width: 991px) {
            margin-bottom: 50px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.block-ar {
    margin-top: 50px;
    .btn {
        display: flex;
        align-items: center;
        float: left;
        margin-top: 25px;
        img {
            width: 30px;
            margin: 0 15px 0 0;            
        }
        &.btn--secondary {
            &:hover {
                img {
                    filter: invert(1);
                }
            }
        }
    }
}