.info-nav {
    position: sticky;
    top: 0;
    padding: 0;
    z-index: 10;
    transition: all 300ms ease-in-out;

    &.is_stuck {
        padding: 10px 0;
        box-shadow: 2px 3px 4px 3px #0000001a;
    }
}

.info-nav__title {
    margin-bottom: 15px;
}

.info-nav__nav {
    width: 100%;
    a {
        font-weight: bold;
        font-family: Speedee-Bd;
        color: #292929;
        margin-right: 25px;
        text-decoration: none;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 2px;
            background: #292929;
            transition: all 600ms ease-in-out;
            bottom: 0;
            left: 0;
        }
        &:hover {
            &:after {
                width: 100%;
            }   
        }
        strong {
            font-family: Speedee-Bd;
            font-weight: bold;
            color: #292929;
        }
    }
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
}

.info {
    h2 {
        margin-bottom: 50px;
        @media screen and (max-width: 991px) {
            margin-bottom: 25px;
        }
    }
}

.info-container {
    margin-bottom: 100px;
    &:last-of-type {
        margin-bottom: 0;
    }
    @media screen and (max-width: 991px) {
        margin-bottom: 50px;
    }
}

.info-section {
    padding: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px #d4d4d4;
    border-radius: 12px;
    //display: flex;
    @media screen and (max-width: 991px) {
        padding: 25px;
        flex-direction: column;
    }
}

.info-block-container {
    display: inline-block;
}

.info-block {
    padding-right: 100px;
    width: 50%;
    float: left;
    h4 {
        margin-bottom: 25px;
        color: #FFBC0D;
        font-weight: bold;
        font-family: Speedee-Bd;
    }
    &:last-of-type {
        h4 {
            color: #D90007;
        }
    }
    @media screen and (max-width: 991px) {
        width: 100%;
        padding: 0;
        &:last-of-type {
            h4 {
                margin-top: 50px;
            }
        }
    }
}

.info-commitment__commitment {
    margin: 25px 0 50px;
}

.info-goals__goal {
    margin-top: 25px;
}

.info-footnote {
    font-size: 10px;
    font-weight: bold;
    margin-top: 25px;
    line-height: 12px;
}