.hero {
    width: 100%;
    padding: 150px 0 50px;
    background: #fff;
    position: relative;

    .logo {
        width: 75px;
        margin-bottom: 15px;
    }

    h1 {
        margin-bottom: 0;
        position: relative;
        z-index: 1;
    }

    p {
        margin: 25px 0 0 0;
        max-width: 450px;
        &:first-of-type {
            margin: 50px 0 0;
        }
    }

    .down-arrow {
        position: absolute;
        bottom: 50px;
        left: calc(50% - 30px);
        width: 60px;
        transition: all 300ms ease-in-out;
        &:hover {
            transform: rotate(360deg);
        }
        @media screen and (max-width: 991px) {
            bottom: 50px;
            left: calc(50% - 22.5px);
            width: 45px;
        }
    }
    
    @media screen and (max-width: 991px) {
        padding: 100px 0 25px;
        .row {
            flex-direction: column;
        }
        p {
            &:first-of-type {
                margin: 25px 0 0;
            }
        }
    }

    &--grey{
        background: #F9F9F9;
    }

    &--large {
        padding: 0;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(../images/large-hero.jpg);
        display: flex;
        align-items: center;
        h1 {
            font-size: 118px;
            line-height: 136px;
            font-weight: 700;    
            .hide {
                display: none;
            }    
        }

        @media screen and (max-width: 991px) {
            padding: 150px 0;
            height: auto;
            h1 {
                font-size: 48px;
                line-height: 58px;
            }
        }
        @media screen and (max-width: 767px) {
            padding: 100px 0 250px;
            background-image: url(../images/large-hero-mobile.jpg);
            &-no-bg {
                background-image: none;
            }
            h1 {
                .hide {
                    display: block;
                }
            }
        }
    }
}

.hero__image {
    width: 100%;
    position: relative;
    z-index: 0;
    @media screen and (max-width: 991px) {
        max-width: 350px;
        margin: 0 auto;
        display: block;
    }
    @media screen and (max-width: 767px) {
        max-width: 300px;
    }
}