@font-face {
    font-family: 'Speedee';
    font-weight: 400;
    src: local('Speedee'), url(./fonts/Speedee_W_Rg.woff) format('woff');
}

@font-face {
    font-family: 'Speedee-Bd';
    font-weight: 700;
    src: local('Speedee-Bd'), url(./fonts/Speedee_W_Bd.woff) format('woff');
}

:root {
    scroll-padding-top: 100px;
    scroll-behavior: smooth;
    @media screen and (max-width: 767px) {
        scroll-padding-top: 200px;
    }
}

body {
    font-size: 19px;
    color: #292929;
    line-height: 32px;
    font-family: Speedee;
    @media screen and (max-width: 991px) {
        font-size: 18px;
        line-height: 29px;
    }
}

h1 {
    font-size: 112px;
    line-height: 128px;
    font-weight: 700;
    font-family: Speedee-Bd;
    margin: 0;
    @media screen and (max-width: 991px) {
        font-size: 43px;
        line-height: 48px;
    }
}

h2 {
    font-style: normal;
    font-weight: normal;
    font-family: Speedee-Bd;
    font-size: 45px;
    line-height: 55px;
    margin: 0;
    @media screen and (max-width: 991px) {
        font-size: 24px;
        line-height: 34px;
    }  
}

h3 {
    font-style: normal;
    font-weight: normal;
    font-family: Speedee-Bd;
    font-size: 34px;
    line-height: 42px;
}

h4 {
    font-style: normal;
    font-family: Speedee-Bd;
    font-weight: normal;
    font-size: 26px;
    line-height: 42px;
}

strong {
    font-family: Speedee-Bd;
}

.yellow {
    color: #FFBC0D;
}

.red {
    color: #D90007;
}

.yellow-bg {
    background: #FFBC0D;
}

.grey-bg {
    background: #F9F9F9;
}

.half-grey-bg {
    background: linear-gradient(to top, #F9F9F9 0%,#F9F9F9 50%,#F9F9F9 50%,white 50%,white 100%);
}

section {
    padding: 100px 0;
    @media screen and (max-width: 991px) {
        padding: 50px 0;
    }
}

.btn--primary {
    background: #FFBC0D;
    color: #292929;
    border-radius: 4px;
    padding: 15px 40px;
    border: 2px solid #FFBC0D;
    transition: all 300ms ease-in-out;
    &:hover {
        background: #fff;
    }
}

.btn--secondary {
    background: #292929;
    color: #fff;
    border-radius: 4px;
    padding: 15px 40px;
    border: 2px solid #292929;
    transition: all 300ms ease-in-out;
    &:hover {
        background: #fff;
        color: #292929;
    }
}

.yellow-text-box {
    background: #FFBC0D;
    border-radius: 12px;
    padding: 50px 25px;
    h3 {
        font-size: 60px;
        line-height: 72px;
        margin-bottom: 25px;
        font-weight: bold;
        font-family: Speedee-Bd;
    }

    h3,
    p {
        max-width: 850px;
    }
}

img.back-top {
    position: fixed;
    bottom: 15px;
    right: 15px;
    background: #fff;
    padding: 0px;
    border-radius: 50%;
    width: 45px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
}