.text-block {
    text-align: center;
    h2 {
        max-width: 650px;
        margin: 0 auto 50px;
        font-family: Speedee-Bd;
        font-weight: bold;
    }
    P {
        max-width: 600px;
        margin: 0 auto 15px;
        &:last-of-type {
            margin-bottom: 50px
        }
    }
    .btn--secondary {
        margin-left: 15px;
    }
    @media screen and (max-width: 991px) {
        h2 {
            max-width: 450px;
            margin: 0 auto 25px;
        }
        p {
            max-width: 425px;
            &:last-of-type {
                margin-bottom: 25px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .btn--secondary {
            margin-left: 0;
            margin-top: 15px;
        }
    }
}